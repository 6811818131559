import React from 'react'
import { graphql } from 'gatsby'
import { MDBContainer } from 'mdbreact'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Hero from '../components/heroTwo'
import NewsSection from '../components/homeNews'
import ColumnOverlayLogos from '../components/columnOverlayLogos'
import ColumnAlternating from '../components/columnAlternatingStyled'
import RecommendedContent from "../components/recommendedContent"
import FeatureCTA from '../components/ctaFeature'
import FadeInWhenVisible from '../components/fadeInWhenVisible'

const Index = ({ data }) => {
  const post = data.markdownRemark
  return (
    <Layout>
      <div id="intro-page">
        <Hero
          class="home-110"
          foreimage={post.frontmatter.foreimage.childImageSharp.gatsbyImageData}
          alt={post.frontmatter.forealttext}
          title={post.frontmatter.title}
          subtitle={post.frontmatter.subtitle}
          ctaslug="#scrollto"
          ctatext="Why UNICOM Engineering?"
          ctaslug2="https://go.unicomengineering.com/Contact-Us?pg-name=home"
          ctatext2="Schedule a consultation"
          type="home"
        />
      </div>

      <div className="position-relative">
        <div className="shape overflow-hidden text-white">
          <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg" >
            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="#FFFFFF" ></path>
          </svg>
        </div>
      </div>

      <main id="main">
        <MDBContainer className="pb-100">
          <ColumnOverlayLogos logobox={post.frontmatter.logobox} />
        </MDBContainer>

        <FeatureCTA
          ctafeaturetitle={post.frontmatter.cta.title}
          ctafeaturesubtitle={post.frontmatter.cta.subtitle}
          ctafeaturelink={post.frontmatter.cta.link}
          ctafeaturelinktext={post.frontmatter.cta.linktext}
          image={post.frontmatter.cta.image.childImageSharp.gatsbyImageData}
          alt={post.frontmatter.cta.alttext}
          bgimage={post.frontmatter.cta.bgimage.childImageSharp.gatsbyImageData}
          bgalt={post.frontmatter.cta.bgalttext}
        />

        {post.frontmatter.section ? (
          <section className="bg-white-grey-gradient">
            <FadeInWhenVisible>
              <h2 className="font-alt font-w-700 title-xs-medium title-extra-large letter-spacing-1 text-center mt-4 mt-xl-0 mb-7"
                dangerouslySetInnerHTML={{ __html: post.frontmatter.spotlighttitle, }} /> 
              <MDBContainer>
                <div className="about-overview">
                  {post.frontmatter.section.map((sections, index) => (
                    <ColumnAlternating
                      key={index}
                      title={sections.title}
                      subtitle={sections.subtitle}
                      subtitlecolour={ sections.subtitlecolour != 'none' ? sections.subtitlecolour : 'text-very-dark-blue' }
                      description={sections.description}
                      image={sections.image.childImageSharp.gatsbyImageData}
                      alt={sections.alttext}
                      placement={sections.placement}
                      titleClass="font-alt font-w-400 letter-spacing-1 mb-3 title-xs-medium title-medium text-very-dark-blue"
                      link={sections.btnlink == 'capabilities' ? '/solutions/' + sections.btnlink : '/' + sections.btnlink }
                      colour={sections.btncolour}
                    />
                  ))}
                </div>
              </MDBContainer>
            </FadeInWhenVisible>
          </section>
        ) : (
          ''
        )}

        <RecommendedContent fallback="whats-new" />
        
        <NewsSection />

      </main>
    </Layout>
  )
}
export default Index

export function Head({ data, location }) {
  const post = data.markdownRemark
  return (
    <SEO
      title={post.frontmatter.meta.title}
      description={post.frontmatter.meta.description}
      url={location.pathname} 
      image={post.frontmatter.foreimage.childImageSharp?.gatsbyImageData?.images?.fallback?.src}   
      canonical={location.pathname} 
    />   
  )
}

export const query = graphql`
  query {
    markdownRemark(frontmatter: { name: { eq: "index" } }) {
      id
      html
      frontmatter {
        meta {
          title
          description 
        }
        title
        subtitle
        bodytitle
        spotlighttitle
        logobox {
          logoboxtitle
          logoboxsubtitle
          logo {
            logoboximage {
              childImageSharp {
                gatsbyImageData(quality: 90) 
              }
            }
            logoboxalttext
            logoboxlink
          }
          logobutton {
            logoboxbtntxt
            logoboxbtnlink
          }
        }
        foreimage {
          childImageSharp {
            gatsbyImageData(quality: 90) 
          }
        }
        forealttext
        section {
          title
          subtitle
          subtitlecolour
          description
          image {
            childImageSharp {
              gatsbyImageData(quality: 90) 
            }
          }
          placement
          alttext
          btnlink
          btncolour
        }
        cta {
          title
          subtitle
          link
          linktext
          image {
            childImageSharp {
              gatsbyImageData(width: 80, height: 98, quality: 90) 
            }
          }
          alttext
          bgimage {
            childImageSharp {
              gatsbyImageData(width: 396, height: 443, quality: 90) 
            }
          }  
          bgalttext      
        }
      }
    }
  }
`