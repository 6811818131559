import React from 'react'
import { MDBMask, MDBRow, MDBCol, MDBView, MDBContainer, MDBBtn, } from 'mdbreact'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faMessageMiddle } from '@fortawesome/pro-regular-svg-icons'
import Tilt from 'react-tilt'
import CustomLink from './CustomLink'

class HeroTwo extends React.Component {
  handleClick(value) {
    typeof window !== 'undefined' && document.querySelector(value).scrollIntoView({
      behavior: 'smooth'
    })
  }
  render() {
    return (
      <section className={this.props.class}>
        <MDBView>
          <MDBMask className={ this.props.gradientClass != null ? this.props.gradientClass : 'mask d-flex justify-content-center align-items-center gradient' } >
            <MDBContainer>
              <MDBRow>
                <MDBCol lg="6" className="my-auto text-lg-left text-center text-white" >
                  <h1
                    className="font-alt font-w-700 title-xs-extra-large title-extra-large-1 mb-5 mb-xl-5"
                    dangerouslySetInnerHTML={{ __html: this.props.title }}
                  />
                  <h2
                    className="font-alt font-w-400 mb-5 title-xs-small title-small letter-spacing-1"
                    dangerouslySetInnerHTML={{ __html: this.props.subtitle, }}
                  />

                  {this.props.ctaslug && (
                    <MDBBtn to={this.props.ctaslug} offset={-150} className="nav-link btn btn-hero-color mr-0 mt-xl-2 btn-w-245 hero-btn1"
                      onClick={() => this.handleClick(this.props.ctaslug) }
                    >
                      <FontAwesomeIcon icon={faChevronDown} className="mr-2" />
                      {this.props.ctatext ? this.props.ctatext : 'Explore'}
                    </MDBBtn>
                  )}

                  {this.props.ctaslug2 && 
                    <CustomLink link={this.props.ctaslug2} className="nav-link btn btn-mdb-color mr-0 mt-xl-2 btn-mdb-border btn-w-245 hero-btn2 noeffect">
                      <FontAwesomeIcon icon={faMessageMiddle} className="mr-2" />
                      {this.props.ctatext2 ? this.props.ctatext2 : 'Schedule a consultation'}
                    </CustomLink>
                  }
                </MDBCol>

                {this.props.foreimage != '' && this.props.foreimage != null && (
                  <MDBCol lg="6" className="mt-5 mt-lg-0 pl-lg-5">
                    <div className="d-none d-lg-block">
                      <Tilt
                        className="Tilt"
                        options={{ max: 25 }}
                        style={{ height: 500, width: 500 }}
                      >
                        <img src={this.props.foreimage.images.fallback.src} loading="lazy" alt={this.props.alt} />
                      </Tilt>
                    </div>
                  </MDBCol>
                )}
              </MDBRow>
            </MDBContainer>
            <div id="scrollto">&nbsp;</div>
          </MDBMask>
        </MDBView>
      </section>
    )
  }
}
export default HeroTwo