import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { MDBContainer } from 'mdbreact'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons'
import FadeInWhenVisible from './fadeInWhenVisible'
import CustomLink from './CustomLink'

class ctaFeature extends React.Component {
  render() {
    return (
      <section className="cta-feature">
        <GatsbyImage
          image={this.props.bgimage}
          className="position-absolute img-bg"
          alt={this.props.bgalt}
        />
        <MDBContainer>
          <div className="cta-feature-inner">
            <div className="media">
              <FadeInWhenVisible direction="down" duration="0.8s" distance={50}>
                <GatsbyImage
                  image={this.props.image}
                  className="mr-5"
                  alt={this.props.alt}
                />
              </FadeInWhenVisible>
              <div className="media-body">
                <h2 className="font-alt font-w-400 letter-spacing-1 title-xs-medium title-medium mt-4 mt-lg-0">
                  {this.props.ctafeaturetitle}
                </h2>
                <div className="font-alt font-w-700 letter-spacing-1 title-xs-medium title-medium">
                  {this.props.ctafeaturesubtitle}
                </div>
              </div>
            </div>

            {this.props.ctafeaturelink &&
              <CustomLink
                link={this.props.ctafeaturelink}
                className="nav-link btn btn-mdb-color btn-sm-block mt-4 mt-lg-0 noeffect"
              >
                <FontAwesomeIcon icon={faChevronRight} className="mr-2" />
                {this.props.ctafeaturelinktext}
              </CustomLink>
            }
          </div>
        </MDBContainer>
      </section>
    )
  }
}
export default ctaFeature