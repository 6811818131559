import React, { Fragment } from 'react'
import { MDBRow, MDBCol } from 'mdbreact'
import { GatsbyImage } from "gatsby-plugin-image"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons'
import FadeInWhenVisible from './fadeInWhenVisible'
import CustomLink from './CustomLink'

class ColumnOverlayLogos extends React.Component {
  render() {
    return (
      <MDBRow>
        <MDBCol lg="12">
          <FadeInWhenVisible direction="up" distance={350} delay={50}>
            <div className="features-absolute rounded bg-white">
              <MDBRow>
                <MDBCol lg="12" md="12" className="my-4">
                  <MDBRow>
                    <MDBCol md="12">
                      <div className="d-flex p-4">
                        <div className="flex-1">
                          <div
                            className="font-alt font-w-200 text-center title-xs-small title-large mb-xl-2 mx-3"
                            dangerouslySetInnerHTML={{ __html: this.props.logobox.logoboxtitle, }}
                          />
                        </div>
                      </div>
                    </MDBCol>

                    <MDBCol md="12">
                      <div className="d-flex">
                        <div className="flex-1">
                          <h2
                            className="font-w-700 text-center font-alt mb-4 title-xs-large mx-3 letter-spacing-1"
                            dangerouslySetInnerHTML={{ __html: this.props.logobox.logoboxsubtitle, }}
                          />
                        </div>
                      </div>
                    </MDBCol>

                    {this.props.logobox.logo.map((logos, index) => (
                      <MDBCol key={index} lg="3" md="6" className="text-center mt-3">
                        <div className="d-flex p-4">
                          <div className="flex-1">
                            <CustomLink link={logos.logoboxlink} className="text-medium-small">
                              <GatsbyImage
                                image={logos.logoboximage.childImageSharp.gatsbyImageData}
                                className="img-fluid mt-2"
                                alt={logos.logoboxalttext}
                              />
                            </CustomLink>
                          </div>
                        </div>
                      </MDBCol>
                    ))}
                  </MDBRow>
                  <div className="btn-toolbar justify-content-center mt-5">
                    {this.props.logobox.logobutton.map((logobuttons, index) => (
                      <CustomLink
                        key={index}
                        link={logobuttons.logoboxbtnlink}
                        className="nav-link btn btn-mdb-color"
                      >
                        <FontAwesomeIcon icon={faChevronRight} className="mr-2" />
                        {logobuttons.logoboxbtntxt}
                      </CustomLink>
                    ))}
                  </div>
                </MDBCol>
              </MDBRow>
            </div>
          </FadeInWhenVisible>
        </MDBCol>
      </MDBRow>
    )
  }
}
export default ColumnOverlayLogos